/* navbar */
.main-nav > *:hover {
  color: grey !important;
}

/* search */
.school-card:hover {
  border: 1px solid #39aa53;
  transition: 0.3s;
}

.club-card:hover {
  border: 1px solid #2c539e;
  transition: 0.3s;
}

.green-link:hover {
  text-decoration: underline;
  text-decoration-color: #34aa25 !important;
  cursor: pointer;
}

.clear-text-button:hover {
  background-color: white !important;
  border-color: #ced4da !important;
  color: #000 !important;
  outline: none !important;
  box-shadow: none !important;
}

.clear-text-button:focus {
  background-color: white !important;
  border-color: #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
}

/* body */
body {
  font-family: "Open Sans", sans-serif !important;
  padding-left: 20%;
  padding-right: 20%;
}

/* general */
.school-color {
  color: #39aa53;
}

.club-color {
  color: #2c539e;
}

.no-select-box:focus {
  outline: none;
  box-shadow: none;
}

/* navbar */
.main-nav {
  font-weight: 600;
  font-size: 18px;
}

.main-nav > * {
  padding-left: 35px !important;
  padding-right: 35px !important;
  color: black !important;
  transition: 0.3s;
}

/* search */
.search-bar {
  border-left-style: none;
  border-right-style: none;
}

.search-bar-empty {
  border-left-style: none;
}

.clear-text-button {
  border-color: #ced4da;
  border-left-style: none !important;
  font-weight: 600;
  font-family: Arial, sans-serif;
}

.filters-form label {
  color: #39aa53;
  font-weight: 600;
}
